
import { CameraStatus } from "@evercam/shared/types/camera"
import Vue from "vue"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"

export default Vue.extend({
  name: "CameraStatusSnackbar",
  computed: {
    ...mapStores(useCameraStore),
    showAlert() {
      return [
        CameraStatus.Offline,
        CameraStatus.OfflineScheduled,
        CameraStatus.UnderMaintenance,
        CameraStatus.WaitingForSiteVisit,
        CameraStatus.Waiting,
        CameraStatus.OnHold,
      ].includes(this.cameraStore.selectedCamera?.status)
    },
  },
})
