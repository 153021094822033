
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import DateTimePicker from "@evercam/shared/components/DateTimePicker"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Vue from "vue"
import {
  AvailableDaysResponsePayload,
  AvailableHoursResponsePayload,
} from "@evercam/shared/types/recording"
import { camelizeKeys } from "humps"
import { useSnapshotStore } from "@/stores/snapshots"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { Moment } from "moment-timezone"
import { debounce } from "@evercam/shared/utils"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer.vue"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons"

import { useRecordingsStore } from "@/stores/recordings"
import { useEventsStore } from "@/stores/events"
import { CameraStatus } from "@evercam/shared/types"
export default Vue.extend({
  components: {
    EvercamPlayer,
    DateTimePicker,
    RightSidebarContent,
    CameraStatusSnackbar,
    PlayersActionButtons,
  },
  props: {
    isSideBar: {
      type: Boolean,
      default: true,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
    cameraId: {
      type: String,
      default: "",
    },

    refreshRate: {
      type: Number,
      default: 12,
    },

    allowZoom: {
      type: Boolean,
      default: true,
    },

    allowPlayPause: {
      type: Boolean,
      default: true,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: true,
    },
    delayBy: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      availableDays: [],
      availableHours: [],
      isLoadingDays: false,
      isLoadingHours: false,
      calendarSelectedTimestamp: this.$moment().toISOString(),
      playerWidth: "100%",
      playerHeight: "100%",
      showLogo: true,
      showCameraName: true,
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useLayoutStore,
      useCameraStore,
      useAccountStore,
      useRecordingsStore,
      useEventsStore
    ),
    showAlert(): boolean {
      return [
        CameraStatus.Offline,
        CameraStatus.OfflineScheduled,
        CameraStatus.UnderMaintenance,
        CameraStatus.WaitingForSiteVisit,
        CameraStatus.Waiting,
        CameraStatus.OnHold,
      ].includes(this.cameraStore.selectedCamera?.status)
    },
    snapshotsInterval(): { from: string; to: string } {
      return {
        from: this.$moment
          .tz(
            this.recordingsStore.selectedTimestamp,
            this.cameraStore.selectedCamera.timezone
          )
          .startOf("hour")
          .utc()
          .format(),
        to: this.$moment
          .tz(
            this.recordingsStore.selectedTimestamp,
            this.cameraStore.selectedCamera.timezone
          )
          .endOf("hour")
          .utc()
          .format(),
      }
    },
    logoDimensions() {
      return this.accountStore.isRecordingWidget
        ? { width: "60px", height: "60px" }
        : { width: "40px", height: "40px" }
    },
    maxDate(): string {
      let maxDate = this.$moment.tz(new Date(), this.timezone)

      if (this.delayBy) {
        maxDate.subtract(this.delayBy, "millisecond")
      }

      return maxDate.toISOString()
    },
    minDate(): string {
      return this.$moment(
        Math.min(
          new Date(this.snapshotStore.oldestSnapshotTimestamp).getTime(),
          new Date(this.cameraStore.selectedCamera?.createdAt).getTime()
        )
      ).toISOString()
    },
    disableOldest(): boolean {
      return (
        this.calendarSelectedTimestamp ===
        this.snapshotStore.oldestSnapshotTimestamp
      )
    },
    disableLatest(): boolean {
      return (
        this.calendarSelectedTimestamp ===
        this.snapshotStore.latestSnapshotTimestamp
      )
    },
    placeholderImage(): string {
      return this.cameraStore.selectedCamera?.largeThumbnailUrl
    },
    timezone(): string {
      return this.cameraStore.selectedCameraTimezone
    },
    zonedSelectedTimestamp(): Moment {
      return this.$moment(this.recordingsStore.selectedTimestamp).tz(
        this.timezone
      )
    },
    selectedDay(): string {
      return this.zonedSelectedTimestamp.format("DD")
    },
    selectedMonth(): string {
      return this.zonedSelectedTimestamp.format("MM")
    },
    selectedYear(): string {
      return this.zonedSelectedTimestamp.format("YYYY")
    },
    filteredAvailableDays(): number[] {
      if (this.delayBy) {
        return this.availableDays.filter((d) =>
          this.isDateRestricted(
            `${this.selectedYear}-${this.selectedMonth}-${d}`
          )
        )
      } else {
        return this.availableDays
      }
    },
    filteredAvailableHours(): number[] {
      if (this.delayBy) {
        return this.availableHours.filter((h) =>
          this.isDateRestricted(
            `${this.selectedYear}-${this.selectedMonth}-${
              this.selectedDay
            }T${String(h).padStart(2, "0")}`
          )
        )
      } else {
        return this.availableHours
      }
    },
  },
  watch: {
    cameraId: {
      immediate: true,
      async handler(cameraId) {
        await this.cameraStore.selectCamera(cameraId)
      },
    },
    calendarSelectedTimestamp: {
      handler(timestamp) {
        if (!this.cameraStore.selectedCamera && !timestamp) {
          return
        }
        this.recordingsStore.selectedTimestamp = this.$moment(timestamp)
          .tz(this.timezone)
          .startOf("hour")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      },
      immediate: true,
    },
    isSideBar: {
      handler(value) {
        if (value && !this.accountStore.isLiveViewWidget) {
          this.layoutStore.enableRightSidebar()
        } else {
          this.layoutStore.disableRightSidebar()
        }
      },
      immediate: true,
    },
    delayBy: debounce(function () {
      // @ts-ignore
      this.onDelayChange()
    }),
  },
  async created() {
    if (
      !this.snapshotStore.cameraExid ||
      this.snapshotStore.cameraExid !== this.cameraId ||
      !this.snapshotStore.latestSnapshotTimestamp ||
      !this.snapshotStore.oldestSnapshotTimestamp ||
      !this.accountStore.isLiveViewWidget ||
      this.delayBy
    ) {
      if (this.delayBy > 0) {
        this.snapshotStore.maxDate = this.maxDate as string
      }
      await this.snapshotStore.updateLatestAndOldestSnapshots(this.cameraId)
    }

    if (!this.accountStore.isLiveViewWidget) {
      this.onInitCalendar()
      this.recordingsStore.isLive = false
    } else {
      this.recordingsStore.isLive = true
    }

    if (this.isEmbedded) {
      this.switchMode()
    }
    const query = camelizeKeys(this.$route.query)
    this.showCameraName = query.cameraName !== "false"
    this.showLogo = query.logo !== "false"
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
  },
  mounted() {
    if (this.isSideBar && !this.accountStore.isLiveViewWidget) {
      this.layoutStore.enableRightSidebar()
    }
    if (!this.recordingsStore.isEdgeVideo) {
      this.cameraStore.setupSocketForSelectedCamera()
    }
    this.accountStore.isRecordingWidget = !this.accountStore.isLiveViewWidget
  },
  methods: {
    onResize({ contentRect }) {
      this.playerHeight = contentRect.height
      this.playerWidth = contentRect.width
    },
    toggleIosFullscreen() {
      this.cameraStore.isCameraTab = !this.cameraStore.isCameraTab
      this.layoutStore.toggleAppBarVisibility()
    },
    onMonthChange(month) {
      if (this.accountStore.isLiveViewWidget) {
        return
      }
      this.fetchAvailableDays(month)
    },
    onDayChange() {
      if (this.accountStore.isLiveViewWidget) {
        return
      }
      this.fetchAvailableHours(this.calendarSelectedTimestamp)
    },
    async onDelayChange() {
      this.snapshotStore.maxDate = this.maxDate as string
      await this.snapshotStore.updateLatestSnapshot(this.cameraId)
      this.recordingsStore.selectedTimestamp =
        this.snapshotStore.latestSnapshotTimestamp
    },
    isDateRestricted(d) {
      return this.$moment.tz(d, this.timezone).isSameOrBefore(this.maxDate)
    },
    async fetchAvailableDays(date) {
      this.isLoadingDays = true
      const datetime = this.$moment(date),
        year = datetime.format("YYYY"),
        month = datetime.format("MM")

      try {
        const { days }: AvailableDaysResponsePayload =
          await EvercamApi.recordings.availableDays({
            cameraId: this.cameraStore.selectedCameraExid || this.cameraId,
            year,
            month,
          })
        this.availableDays = days
      } catch (err) {
        this.$errorTracker.save(err)
      } finally {
        this.isLoadingDays = false
      }
    },
    async fetchAvailableHours(date) {
      this.isLoadingHours = true
      const datetime = this.$moment(date),
        year = datetime.format("YYYY"),
        month = datetime.format("MM"),
        day = datetime.format("DD")

      try {
        const { hours }: AvailableHoursResponsePayload =
          await EvercamApi.recordings.availableHours({
            cameraId: this.cameraStore.selectedCameraExid || this.cameraId,
            year,
            month,
            day,
          })

        this.availableHours = hours
      } catch (err) {
        this.$errorTracker.save(err)
      }
      this.isLoadingHours = false
    },
    oldestSnapshot() {
      this.calendarSelectedTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp
    },
    latestSnapshot() {
      this.calendarSelectedTimestamp =
        this.snapshotStore.latestSnapshotTimestamp
    },
    switchMode() {
      this.$theme.switchTheme({
        isDark: this.isDarkMode,
        disablePersistToLocalStorage: this.isEmbedded,
      })
    },
    onInitCalendar() {
      let timestamp = (camelizeKeys(this.$route.query) as Record<string, any>)
        ?.dateTime
      this.calendarSelectedTimestamp =
        timestamp && this.$moment(timestamp).isValid()
          ? timestamp
          : this.snapshotStore.latestSnapshotTimestamp

      this.fetchAvailableDays(this.calendarSelectedTimestamp)
      this.fetchAvailableHours(this.calendarSelectedTimestamp)
    },
  },
})
